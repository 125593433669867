<template>
  <template v-if="state.dataIsLoaded">
    <header class="header">
      <div class="header__logo">
        <img src="@/assets/images/converted/header.webp" alt="uniqom logo">
      </div>
      <nav class="header__nav">
        <div class="nav__item">
          <a href="#about">About Us</a>
          <div class="header__underline"></div>
        </div>
        <div class="nav__item">
          <a href="#supplier-services">Supplier Services</a>
          <div class="header__underline"></div>
        </div>
        <div class="nav__item">
          <a href="#customer-services">Customer Services</a>
          <div class="header__underline"></div>
        </div>
        <div class="nav__item">
          <a href="#news">News</a>
          <div class="header__underline"></div>
        </div>
        <div class="nav__item">
          <a href="#geography">Our Geography</a>
          <div class="header__underline"></div>
        </div>
        <div class="nav__item">
          <a href="#contact">Contact Us</a>
          <div class="header__underline"></div>
        </div>
      </nav>
      <div class="header__lang" tabindex="0">
        <div class="lang__en"><span>EN</span></div>
        <div class="lang__ru"><a href="https://uniqom.ru/"><span>RU</span></a></div>
      </div>
      <div
          class="nav-dropdown__btn"
          @click="state.menu.isOpen = !state.menu.isOpen"
          v-click-outside="onClickOutsideMenu"
      >
        <img src="@/assets/images/menu.png" alt="nav-btn" style="width: 15px">
      </div>
      <div class="nav-dropdown">
        <transition name="expand">
          <ul
              v-if="state.menu.isOpen"
              style="padding: 0; margin: 0; overflow: hidden"
          >
            <li class="nav__item"><a href="#about">About Us</a></li>
            <li class="nav__item"><a href="#supplier-services">Supplier Services</a></li>
            <li class="nav__item"><a href="#customer-services">Customer Services</a></li>
            <li class="nav__item"><a href="#news">News</a></li>
            <li class="nav__item"><a href="#geography">Our Geography</a></li>
            <li class="nav__item"><a href="#contact">Contact Us</a></li>
            <li class="nav__item"><a class="accent" href="https://uniqom.ru">Russian website</a></li>
          </ul>
        </transition>
      </div>
    </header>

    <section
        class="intro"
        v-if="state.dataIsLoaded && state.intro.value.isEnabled "
    >
      <div class="intro__wrapper">
        <h2 class="intro__title title_medium">{{ state.intro.value.title }}</h2>
        <div class="intro__subtitle" v-html="state.intro.value.subtitle"></div>
        <a href="#about" class="intro__btn">Learn more</a>
      </div>
    </section>
    <section
        class="company-in-numbers d-flex-center"
        v-if="state.number.value.isEnabled"
    >
      <div class="container">
        <div class="wrapper">
          <h2
              class="company-in-numbers__title title_medium"
              data-aos="fade-up"
          >
            {{ state.number.value.title }}
          </h2>
          <div class="numbers">
            <div v-for="counter in state.number.value.payload" :key="counter.id"
                 class="number"
                 data-aos="fade-up"
            >
              <h1 class="number__counter">{{ counter.counter }}</h1>
              <span class="number__title">{{ counter.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
        id="about"
        class="about d-flex-center"
        v-if="state.about.value.isEnabled"
    >
      <div class="container">
        <div class="wrapper">
          <h2
              class="about__title title_medium"
              data-aos="fade-up"
          >{{ state.about.value.title }}</h2>
          <div
              class="delimeter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <div class="about__article" data-aos="fade-up-right">
            <div style="position: relative">
              <div class="article__substrate"></div>
              <div class="article__img"></div>
            </div>
            <p class="article__text" v-html="state.about.value.subtitle"></p>
          </div>
        </div>
      </div>
    </section>
    <section
        class="misson d-flex-center"
        v-if="state.mission.value.isEnabled"
    >
      <div class="container">
        <div class="mission__wrapper">
          <h2
              class="mission__title title_medium"
              data-aos="fade-up"
          >{{ state.mission.value.title }}</h2>
          <div class="delimeter" v-if="state.mission.value.hasDelimiter"
               data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <div class="mission__content" v-html="state.mission.value.subtitle"
               data-aos="fade-up"
          ></div>
        </div>
      </div>
    </section>
    <section
        class="priority d-flex-center"
        v-if="state.priority.value.isEnabled"
    >
      <div class="container">
        <div class="priority__wrapper">
          <h2
              class="priority__title title_medium"
              data-aos="fade-up"
          >{{ state.priority.value.title }}</h2>
          <div
              class="delimeter" v-if="state.priority.value.hasDelimiter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <div class="priority__list">
            <div
                class="priority__item"
                v-for="(item, index) in state.priority.value.payload"
                :key="item.id"
                data-aos="fade-up"
                :data-aos-delay="50*2*index"
            >
              <img :src="getStaticImage('priorities', item.image)" alt="" class="item__img">
              <div class="item__content">
                <h3 class="item__title" v-html="item.title"></h3>
                <p class="item__text" v-html="item.text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- add history carousel -->
    <history
        :title="state.history.value.title"
        :subtitle="state.history.value.subtitle"
        :has-delimiter="state.history.value.hasDelimiter"
        :payload="state.history.value.payload"
        v-if="state.history.value.isEnabled"
        data-aos="fade-up"
    />
    <section
        class="team d-flex-center"
        v-if="state.team.value.isEnabled"
    >
      <div class="container">
        <div class="team__wrapper">
          <h2
              class="team__title title_medium"
              data-aos="fade-up"
          >{{ state.team.value.title }}</h2>
          <div
              class="delimeter"
              v-if="state.team.value.hasDelimiter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <p
              class="team__text"
              v-html="state.team.value.subtitle"
              data-aos="fade-up"
          ></p>
          <img
              src="@/assets/images/converted/team.webp"
              alt="team"
              class="team__img"
              data-aos="fade-up"
          >
        </div>
      </div>
    </section>
    <section
        id="supplier-services"
        class="supplier-services d-flex-center"
        v-if="state.supplierService.value.isEnabled"
    >
      <div class="container">
        <div class="supplier-services__wrapper">
          <h2
              class="supplier-services__title title_medium"
              data-aos="fade-up"
          >
            {{ state.supplierService.value.title }}
          </h2>
          <div
              class="delimeter"
              v-if="state.supplierService.value.hasDelimiter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <div class="service__list">
            <div
                class="service__item"
                v-for="(service, index) in state.supplierService.value.payload"
                :key="service.id"
                data-aos="fade-up"
                :data-aos-delay="50*2*index"
            >
              <img
                  :src="getStaticImage('services', service.image)"
                  alt="service"
                  class="service__img"
              >
              <h3 class="service__title">{{ service.title }}</h3>
              <p class="service__subtitle" v-html="service.subtitle"></p>
              <a class="service__link" @click="setToPopup(service)">View more</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
        id="customer-services"
        class="customer-services d-flex-center"
        v-if="state.customerService.value.isEnabled"
    >
      <div class="container">
        <div class="supplier-services__wrapper">
          <h2
              class="supplier-services__title title_medium"
              data-aos="fade-up"
          >
            {{ state.customerService.value.title }}
          </h2>
          <div
              class="delimeter"
              v-if="state.customerService.value.hasDelimiter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <div class="service__list">
            <div
                class="service__item"
                v-for="(service, index) in state.customerService.value.payload"
                :key="service.id"
                data-aos="fade-up"
                :data-aos-delay="50*2*index"
            >
              <img
                  :src="getStaticImage('services', service.image)"
                  alt="service"
                  class="service__img"
              >
              <h3 class="service__title">{{ service.title }}</h3>
              <p class="service__subtitle" v-html="service.subtitle"></p>
              <a class="service__link" @click="setToPopup(service)">View more</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="brands__circle-section">
      <div class="brands__circle"></div>
    </section>
    <section
        class="brands d-flex-center"
        v-if="state.brand.value.isEnabled"
    >
      <div class="container">
        <div class="brands__wrapper">
          <h2
              class="brands__title title_medium"
              data-aos="fade-up"
          >{{ state.brand.value.title }}</h2>
          <div
              class="delimeter"
              v-if="state.brand.value.hasDelimiter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <p
              class="brands__text paragraph_ta-center"
              v-html="state.brand.value.subtitle"
              data-aos="fade-up"
          ></p>
          <hr style="background: rgba(0, 0, 0, 0.1)" data-aos="fade-up">
          <div class="brands__carousel" style="text-align:center">
            <carousel
                :settings="carouselConfig.brand"
                :breakpoints="carouselConfig.brand.breakpoints"
                :wrap-around="true"
                :autoplay="20000"
            >
              <slide v-for="brand in state.brand.value.payload" :key="brand.id">
                <img
                    :src="getStaticImage('brands', brand.image)"
                    :alt="brand.title"
                    style="max-width: 150px"
                >
              </slide>
              <template #addons>
                <navigation />
              </template>
            </carousel>
          </div>
          <hr data-aos="fade-up">
        </div>
      </div>
    </section>
    <section
        id="news"
        class="news d-flex-center"
        v-if="state.news.value.isEnabled"
    >
      <div class="container">
        <div class="news__wrapper">
          <h2
              class="news__title title_medium"
              data-aos="fade-up"
          >
            {{ state.news.value.title }}
          </h2>
          <div
              class="delimeter"
              v-if="state.news.value.hasDelimiter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <carousel
              :breakpoints="carouselConfig.breakpoints"
              :settings="carouselConfig.news"
              data-aos="fade-up"
          >
            <slide v-for="item in state.news.value.payload" :key="item.id">
              <div class="news__item">
                <img class="item__img" :src="getStaticImage('news', item.image)" alt="news-img">
                <h2 class="item__title">
                  {{ item.title }}
                </h2>
                <div class="item__date">{{ item.date }}</div>
                <p class="item__subtitle">{{ item.subtitle }}</p>
                <a class="item__link" @click="setToPopup(item)">View more</a>
              </div>
            </slide>
            <template #addons>
              <navigation />
            </template>
          </carousel>
        </div>
      </div>
    </section>
    <section
        id="geography"
        class="geography d-flex-center"
        v-if="state.geography.value.isEnabled"
    >
      <div class="container">
        <div class="geography__wrapper">
          <h2
              class="geography__title title_medium"
              data-aos="fade-up"
          >
            {{ state.geography.value.title }}
          </h2>
          <div
              class="delimeter"
              v-if="state.geography.value.hasDelimiter"
              data-aos="fade-up"
          >
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <p
              class="geography__text paragraph_ta-center"
              v-html="state.geography.value.subtitle"
          ></p>
        </div>
      </div>
    </section>
    <yandex-map :objects="state.offices" data-aos="fade-up"></yandex-map>
    <section
        class="video d-flex-center"
        v-if="state.video.value.isEnabled"
        data-aos="fade-up"
    >
      <div class="container">
        <div class="video__wrapper">
          <h2 class="video__title title_medium">{{ state.video.value.title }}</h2>
          <div class="delimeter">
            <img class="dots" src="@/assets/images/dots.png" alt="dots">
          </div>
          <p class="video__text paragraph_ta-center" v-html="state.video.value.subtitle"></p>
          <div class="video__img" @click="state.videoPopup = true">
            <img src="@/assets/images/converted/video.webp" alt="video">
          </div>
        </div>
      </div>
    </section>
    <div
        class="video__popup"
        v-if="state.videoPopup"
        @click.self="state.videoPopup = !state.videoPopup"
        data-aos="fade-up"
    >
      <div class="popup__wrapper">
        <iframe
            class="video__frame"
            src="https://www.youtube.com/embed/-Enps5WLFpk"
            title="YouTube video player"
            frameborder="0"
            allow="
            accelerometer;
            autoplay; clipboard-write;
            encrypted-media;
            gyroscope; picture-in-picture
          "
            allowfullscreen>
        </iframe>
      </div>
    </div>
    <section class="gallery d-flex-center">
      <div class="container">
        <div class="gallery__wrapper">
          <p
              class="gallery__title"
              @click="gellaryConfig.blockVisible = !gellaryConfig.blockVisible"
          >
            Photo Gallery
          </p>
          <div class="gallery__list" v-if="gellaryConfig.blockVisible">
            <div
                v-for="(img, index) in state.gallery.value"
                :key="index"
                @click="() => showImg(index)"
                class="gallery__item"
            >
              <img :src="img.src"/>
            </div>
          </div>
          <vue-easy-lightbox
              :visible="gellaryConfig.lightboxVisible"
              :imgs="state.gallery.value"
              :index="gellaryConfig.imageIndex"
              :move-disabled="true"
              @hide="handleHide"
          >
            <template v-slot:toolbar/>
          </vue-easy-lightbox>
        </div>
      </div>
    </section>
    <footer id="contact" class="footer d-flex-center">
      <div class="container">
        <div class="footer__wrapper">
          <div class="footer__text">
            <h1 class="footer-contact__title">Contact us</h1>
            <p class="footer-contact__subtitle">
              We are open for new partnerships!
            </p>
            <h2 class="footer-contact__title-email">Email</h2>
            <p class="footer-contact__email">vladivostok@uniqom.ru</p>
            <h2 class="footer-contact__title-hours">Office Hours (+10 UTC)</h2>
            <p class="footer-contact__hours">
              Mon - Fri 9 am - 6 pm <br>
              Sat 9 am - 4 pm <br>
              Sun closed <br>
            </p>
          </div>
          <div class="footer__form">
            <h1 class="form__title">Contact Form</h1>
            <form class="form__fields" @submit.prevent>
              <input
                  class="form__field-text"
                  v-model="state.form.name"
                  type="text"
                  placeholder="Full Name"
                  required
              >
              <input
                  class="form__field-text"
                  v-model="state.form.email"
                  type="email"
                  placeholder="Email"
                  required
              >
              <textarea
                  class="form__field-textarea"
                  maxlength="5000"
                  placeholder="Message"
                  v-model="state.form.message"
                  required
              ></textarea>
              <button @click="recaptcha" class="form__btn">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </footer>
    <popup
        v-if="popupTriggers.buttonTrigger"
        :togglePopup="() => togglePopup('buttonTrigger')"
    >
      <div class="popup__title">{{ state.popupContent.title }}</div>
      <div class="popup__text" v-html="state.popupContent.text"></div>
    </popup>
  </template>
</template>

<script>
import axios from 'axios';
import { ref, reactive } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import
{
  Carousel,
  Slide,
  Navigation,
} from 'vue3-carousel';
import AOS from 'aos';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import vClickOutside from 'click-outside-vue3';
import { useReCaptcha } from 'vue-recaptcha-v3';
import utils from './utils';
import carouselConfig from './carousel';
import History from './components/History.vue';
import Popup from './components/Popup.vue';
import YandexMap from './components/Map.vue';

export default {
  components: {
    History,
    Carousel,
    Slide,
    Navigation,
    Popup,
    YandexMap,
    VueEasyLightbox,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const { getStaticImage } = utils();
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const state = reactive({
      intro: {},
      number: {},
      about: {},
      mission: {},
      priority: {},
      history: {},
      team: {},
      supplierService: {},
      customerService: {},
      brand: {},
      news: {},
      geography: {},
      video: {},
      offices: [],
      gallery: [],
      menu: {
        isOpen: false,
      },
      form: {
        name: null,
        email: null,
        message: null,
      },
      popupContent: null,
      videoPopup: false,
      dataIsLoaded: false,
    });

    const gellaryConfig = reactive({
      imageIndex: 0,
      lightboxVisible: false,
      blockVisible: false,
    });

    const fetchData = (target, isLast = false) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/${target}`)
        .then((response) => {
          state[target].value = response.data;
        }).then(() => {
          if (isLast) {
            state.dataIsLoaded = true;
          }
        });
    };

    const postFeedbackForm = (token) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/post-form`, {
          name: state.form.name,
          email: state.form.email,
          message: state.form.message,
          token,
        })
        .then((response) => {
          if (response.data.success) {
            state.form.name = null;
            state.form.email = null;
            state.form.message = null;
          }
        });
    };

    const recaptcha = async () => {
      await recaptchaLoaded();
      const token = await executeRecaptcha('login');

      postFeedbackForm(token);
    };

    const onClickOutsideMenu = () => {
      state.menu.isOpen = false;
    };

    const popupTriggers = ref({
      buttonTrigger: false,
    });

    const togglePopup = (trigger) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };

    const setToPopup = (data) => {
      state.popupContent = data;
      togglePopup('buttonTrigger');
    };

    const showImg = (index) => {
      gellaryConfig.imageIndex = index;
      gellaryConfig.lightboxVisible = true;
    };

    const handleHide = () => {
      gellaryConfig.lightboxVisible = false;
    };

    fetchData('brand');
    fetchData('offices');
    fetchData('gallery');
    fetchData('team');
    fetchData('intro');
    fetchData('number');
    fetchData('about');
    fetchData('mission');
    fetchData('priority');
    fetchData('history');
    fetchData('supplierService');
    fetchData('customerService');
    fetchData('news');
    fetchData('video');
    fetchData('geography', true);

    AOS.init();

    return {
      state,
      getStaticImage,
      carouselConfig,
      setToPopup,
      popupTriggers,
      togglePopup,
      postFeedbackForm,
      showImg,
      handleHide,
      gellaryConfig,
      onClickOutsideMenu,
      recaptcha,
    };
  },
};
</script>

<style lang="scss">
@import './assets/sass/main'
</style>
