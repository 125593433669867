<template>
  <div class="newyear-container ru">
    <div class="inform is-launch">
      <div class="inform-container">
        <div class="inform-window">
          <div class="inform-image">
            <img src="@/assets/images/new_year.png" alt="new_year" />
          </div>
          <div class="inform-text">
            Уважаемый партнер, с Новым Годом!
          </div>
          <div class="inform-button">
            <button class="btn btn-5" @click="launchMedia">Посмотреть поздравление</button>
          </div>
        </div>
      </div>
    </div>
    <div class="media">
      <video preload="auto" playsinline>
        <source
            src="@/assets/media/Ru.Uniqom_Happy_New_Year.mp4"
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        >
        Тег video не поддерживается вашим браузером.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    launchMedia() {
      const video = document.querySelector('video');
      video.play();
      document.querySelector('.inform').classList.remove('is-active');
      video.addEventListener('ended', this.restart, false);
    },
    restart() {
      const video = document.querySelector('video');
      document.querySelector('.inform').classList.add('is-active');
      document.querySelector('.inform-button button').innerHTML = 'Смотреть еще раз?';
      video.removeEventListener('ended', this.restart, false);
    },
  },
  mounted() {
    document.title = 'Уважаемый партнер, с Новым Годом! - Uniqom';
    setTimeout(() => {
      document.querySelector('.inform').classList.add('is-active');
      document.querySelector('.inform').classList.remove('is-launch');
    }, 350);
  },
};
</script>

<style lang="scss">
@import "./assets/sass/new_year.scss";
</style>
