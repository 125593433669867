<template>
  <div class="popup">
    <div class="popup__wrapper">
      <button @click="togglePopup" class="popup__close">
        <img src="@/assets/images/cancel.svg" alt="cancel" class="popup__close-img">
      </button>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'popup',
  props: ['togglePopup'],
};
</script>
