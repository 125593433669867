<template>
  <div
  id="map"
  style="width: 100%; height: 780px"
  ></div>
</template>

<script>
import utils from '../utils';

export default {
  name: 'yamdexMap',
  props: {
    objects: Array,
  },
  setup(props) {
    const { getStaticImage } = utils();

    let mapZoom = 4;
    if (window.screen.width <= 576) {
      mapZoom = 2;
    }

    window.ymaps.ready(() => {
      const map = new window.ymaps.Map('map', {
        center: [55.030204, 82.920430],
        zoom: mapZoom,
        controls: ['zoomControl', 'fullscreenControl'],
      });

      map.behaviors.disable('scrollZoom');
      if (/Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        map.behaviors.disable('drag');
      }

      const placemarks = [];
      props.objects.value.forEach((object) => {
        let icon = 'map-marker.svg';
        if (object.isHead) {
          icon = 'map-marker-star.svg';
        }

        placemarks.push(new window.ymaps.Placemark([object.latitude, object.longitude], {
          balloonContent: `<div class='balloon-content'>
            <h1 class='balloon__title'>${object.city}</h1>
            <p class='balloon__type'>${object.type}</p>
            <p class='balloon__address'>${object.postcode}, ${object.city}, ${object.street} <br />Phone: ${object.phone} </p>
            <p class='balloon__email'><a href='mailto:${object.email}'>${object.email}</a></p>
          </div>`,
        }, {
          iconLayout: 'default#image',
          iconImageHref: getStaticImage('icons', icon),
        }));
      });

      placemarks.forEach((placemark) => {
        map.geoObjects.add(placemark);
      });
    });
  },
};
</script>
