export default {
  breakpoints: {
    576: { itemsToShow: 1 },
    823: { itemsToShow: 2 },
    1024: { itemsToShow: 3 },
  },

  brand: {
    autoplay: 2000,
    breakpoints: {
      576: { itemsToShow: 1 },
      823: { itemsToShow: 3 },
      1024: { itemsToShow: 5 },
    },
  },

  news: {
    snapAlign: 'start',
  },
};
