<template>
  <section
    id="history"
    class="history d-flex-center"
  >
    <div class="container">
      <div class="history__wrapper">
        <div class="history__title">{{ title }}</div>
        <div class="delimeter" v-if="hasDelimiter">
          <img class="dots" src="@/assets/images/dots.png" alt="dots">
        </div>
        <p class="history__text paragraph_ta-center" v-html="subtitle"></p>
      </div>
      <div class="history__slider">
        <div class="history__slide">
          <carousel
              :breakpoints="carouselConfig.breakpoints"
          >
            <slide v-for="history in payload" :key="history.id">
              <div class="history__item">
                <div class="history__timeline">
                  <div class="history__timeline__point">{{ history.title }}</div>
                </div>
                <div class="history__circle"></div>
                <div class="history__pointdesc">
                  <img :src="getStaticImage('history', history.image)" :alt="history.image"
                    class="history__image"
                  >
                  <p class="history__paragraph" v-html="history.text"></p>
                </div>
              </div>
            </slide>
            <template #addons>
              <navigation />
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import
{
  Carousel,
  Slide,
  Navigation,
} from 'vue3-carousel';
import utils from '../utils';
import carouselConfig from '../carousel';

export default {
  name: 'History',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    title: String,
    subtitle: String,
    hasDelimiter: Boolean,
    payload: Array,
  },
  setup() {
    const { getStaticImage } = utils();

    return {
      getStaticImage,
      carouselConfig,
    };
  },
};
</script>
