import { createApp } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import Home from './Home.vue';
import NewYear from './NewYear.vue';
import NewYearRu from './NewYearRu.vue';
import 'aos/dist/aos.css';

const app = createApp(App);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/HappyNewYear',
    name: 'newYear',
    component: NewYear,
  },
  {
    path: '/ru/HappyNewYear',
    name: 'newYear-ru',
    component: NewYearRu,
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

app.use(
  VueReCaptcha,
  { siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY },
);
app.use(router);
app.mount('#app');
