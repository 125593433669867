<template>
  <div class="newyear-container">
    <div class="inform is-launch">
      <div class="inform-container">
        <div class="inform-window">
          <div class="inform-image">
            <img src="@/assets/images/car.png" alt="new_year" />
          </div>
          <div class="inform-text">
            <img src="@/assets/images/ny_text.png" alt="new_year_text" />
          </div>
          <div class="inform-button">
            <button class="btn btn-5" @click="launchMediaRu">Смотреть РУС версию</button>
            <button class="btn btn-5" @click="launchMedia">Watch the ENG version</button>
          </div>
        </div>
      </div>
    </div>
    <div class="media">
      <video id="ru" preload="auto" playsinline>
        <source
            src="@/assets/media/Happy_New_Year_from_Uniqom_2023_ru.mp4"
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        >
        Тег video не поддерживается вашим браузером.
      </video>
      <video id="en" preload="auto" playsinline>
        <source
            src="@/assets/media/Happy_New_Year_from_Uniqom_2023.mp4"
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        >
        Тег video не поддерживается вашим браузером.
      </video>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  methods: {
    launchMedia() {
      document.getElementById('en').style.display = 'block';
      const video = document.getElementById('en');
      document.getElementById('ru').style.display = 'none';
      video.play();
      document.querySelector('.inform').classList.remove('is-active');
      video.addEventListener('ended', this.restart, false);

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
          .test(navigator.userAgent)) {
        this.openFullscreen(video)
      }
    },
    launchMediaRu() {
      document.getElementById('ru').style.display = 'block';
      const video = document.getElementById('ru');
      document.getElementById('en').style.display = 'none';
      video.play();
      document.querySelector('.inform').classList.remove('is-active');
      video.addEventListener('ended', this.restart, false);

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
          .test(navigator.userAgent)) {
        this.openFullscreen(video)
      }
    },
    restart() {
      const video = document.getElementById('en');
      document.querySelector('.inform').classList.add('is-active');
      // document.querySelector('.inform-button button').innerHTML = 'Watch again?';
      video.removeEventListener('ended', this.restart, false);
    },
    restartRu() {
      const video = document.getElementById('ru');
      document.querySelector('.inform').classList.add('is-active');
      // document.querySelector('.inform-button button').innerHTML = 'Watch again?';
      video.removeEventListener('ended', this.restart, false);
    },
    openFullscreen(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      document.querySelector('.inform').classList.add('is-active');
      document.querySelector('.inform').classList.remove('is-launch');
    }, 350);
  },
};
/* eslint-enable */
</script>

<style lang="scss">
  @import "./assets/sass/new_year.scss";
</style>
